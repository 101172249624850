@import url('https://fonts.googleapis.com/css?family=Petrona&display=swap');


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .App {
    margin: 25px 0;
    font-family: 'Petrona', serif;
  }

  .category-name {
    text-decoration: underline blue;
    display: list-item;
  }

  .header {
    position: fixed;
    display: block;
    z-index: 2;
    transition: top 0.3s;
    background: #641F5E;
    color: white;
    top: 0;
    width: 100%;

    .name {
      font-size: 1.5em;
      display: grid;
      text-align: center;
    }

    .calNav,
    .payNav {
      display: none;
    }
  }

  .quarter-cell {
    justify-self: start;
  }

  #tabs {
    left: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 2;
    transition: bottom 0.3s;
    border-top: #65AC92 solid 1px;
    background: white;
  }

}

/*  600px and up */
@media only screen and (min-width: 600px) {
  .highlight {
    background: #f0eeec;
  }

  .App {
    margin: 0 10%;
  }

  #tabs {
    display: none;
  }

  .header {
    -webkit-box-shadow: 0 0 0.5rem rgba(255, 182, 193, 1);
    -moz-box-shadow: 0 0 0.5rem rgba(83, 78, 76, .5);
    box-shadow: 0 0 0.5rem rgba(83, 78, 76, .5);
    padding: 0 10%;
    background: #641F5E;
    color: white;

    .navCategory {
      position: relative;
    }

    .navCategory::before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: #AFB048;
      transform-origin: center;
      transform: translate(-50%, 0) scaleX(0);
      transition: transform 0.3s ease-in-out;
    }

    .navCategory:hover::before {
      transform: translate(-50%, 0) scaleX(1);
    }

    .name {
      padding: 5px;
      font-size: 1.5em;
      display: inline-block;
    }

    .calNav,
    .payNav {
      margin: 12px;
      text-decoration: none;
      color: inherit;

    }
  }

  #tabs {
    width: 100%;
    z-index: 2;
    background: white;
  }

  .category-modal {

    .category-name {
      position: relative;
      z-index: 1;
    }

    .category-name::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: -0.25em;
      right: -0.25em;
      background-color: #FFB6C1;
      transform-origin: center right;
      transform: scaleX(0);
      transition: transform 0.2s ease-in-out;
    }

    .category-name:hover::before {
      transform: scaleX(1);
      transform-origin: center left;
    }

    .category-name:hover {
      cursor: pointer;
    }
  }

  .table {
    text-align: center;

    .quarter-cell {
      justify-self: center;
    }

    .category-cell {
      justify-self: center;
      align-self: center;
    }
  }
}


.table {
  padding: 0 10%;

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-top: solid lightgray 1px;
    padding: 3%;

    .quarter-cell {
      grid-column: 1/2;
      display: grid;
      align-self: center;
    }

    .category-cell {
      grid-column: 2/-1;
    }
  }
}


.container {
  margin: 50px 0;
  flex-grow: 1;
}

.loading-container {
  display: grid;
  justify-self: center;
  align-items: center;
  height: 500px;
  z-index: 1;
}

#mobile-calendar {
  margin: 0;
}

//slides

.slide {
  overflow-scrolling: touch;
}

.slide2 {
  background: #B3DC4A;
  min-height: 100px;
}

.slide3 {
  background: #6AC0FF;
  min-height: 300px;
}

.tabs {
  background: white;
}

/* Loading Component */

.loader {
  position: relative;
}

.outer,
.middle,
.inner {
  border: 3px solid transparent;
  border-top-color: #3cefff;
  border-right-color: #3cefff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
}

.outer {
  width: 3.5em;
  height: 3.5em;
  margin-left: -1.75em;
  margin-top: -1.75em;
  animation: spin 2s linear infinite;
}

.middle {
  width: 2.1em;
  height: 2.1em;
  margin-left: -1.05em;
  margin-top: -1.05em;
  animation: spin 1.75s linear reverse infinite;
}

.inner {
  width: 0.8em;
  height: 0.8em;
  margin-left: -0.4em;
  margin-top: -0.4em;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
